import * as styles from '../styles/blogPages.module.css';

import { Link, graphql } from 'gatsby';
import { getBlogPostTags, getUrl } from '../util';

import BlogCard from '../components/Card/BlogCard';
import CardGrid from '../containers/CardGrid/CardGrid';
import DefaultImage from '../images/Default-Preview.png';
import Layout from '../containers/layout';
import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import slugify from 'slugify';

const Blog = ({ data, location, pageContext }) => {
  const urlArr = data.allSitePage.nodes;

  const {
    humanPageNumber,
    nextPagePath,
    pageNumber,
    previousPagePath,
    numberOfPages,
  } = pageContext;

  const blogPostsData = data.allStrapiBlogs.nodes;

  const blogPosts = blogPostsData.map((post, i) => {
    return (
      <BlogCard
        title={post.Title}
        author={post.Author}
        date={post.Date}
        img={
          post.CoverImage ? getImage(post.CoverImage.localFile) : DefaultImage
        }
        link={getUrl(urlArr, post.id)}
        tags={getBlogPostTags(post)}
        key={i}
      />
    );
  });

  return (
    <Layout location={location}>
      <h1>All Blogs</h1>
      <CardGrid show>{blogPosts}</CardGrid>
      <div className={styles.Navigate}>
        <div className={styles.PageNavButtons}>
          {humanPageNumber !== 1 && (
            <>
              <span className={styles.Button}>
                <Link to={`${previousPagePath}`}>Previous Page</Link>
              </span>
              <span className={styles.Divider}>|</span>
            </>
          )}
          {numberOfPages > 1 && (
            <span className={styles.Button}>
              <Link to={`${nextPagePath}`}>Next Page</Link>
            </span>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const blogPageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiBlogs(
      sort: { fields: Date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        Title
        CoverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
        Date
        Tags
        Author
      }
    }
    allSitePage {
      nodes {
        context {
          id
        }
        path
      }
    }
  }
`;
